import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import SectionTitle from "@ui/section-title";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Button from "@ui/button";
import {
  BoxSectionWrapper,
  BoxSectionInner,
  BoxSectionWrap,
  BoxSectionWrapInner,
  BoxSectionIconwrap,
  BoxSectionTextWrap,
} from "./style";
const BoxSection = ({ data, layout, ...props }) => {
  return (
    <BoxSectionWrapper layout={layout} {...props} id={data.section}>
      <BoxSectionInner>
        <Container>
          <Row>
          {data.section_title && (
            <Col>
                <SectionTitle
                subtitle={data.section_title.subtitle}
                title={data.section_title.title}
                description={data.section_title.description}
                />
            </Col>
            )}
          </Row>
          <Row>
            <Col>
              <BoxSectionWrap>
                {!!data &&
                  data?.items?.map((el, index) => (
                    <BoxSectionWrapInner key={index}>
                      <BoxSectionIconwrap>
                        <Image
                          src={el.images[0]?.src}
                          alt={el.images[0]?.alt || "Icon"}
                          title={el.images[0]?.alt || "Icon"}
                        />
                      </BoxSectionIconwrap>
                      <BoxSectionTextWrap>
                        <Heading as="h4" className="text-white">
                          {el.title}
                        </Heading>
                        {el.texts && el.texts[0].content && (
                          <Text>
                            {el.texts[0]?.content}
                          </Text>
                        )}
                        {el.texts && el.texts[1].content && (
                          <Text>
                            {el.texts[1]?.content}
                          </Text>
                        )}
                        <Text className="desc">{el.description}</Text>
                      </BoxSectionTextWrap>
                        <Button path={el.path} className="customBtn">
                            Learn More
                        </Button>
                    </BoxSectionWrapInner>
                  ))}
              </BoxSectionWrap>
            </Col>
          </Row>
        </Container>
      </BoxSectionInner>
    </BoxSectionWrapper>
  );
};

BoxSection.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3, 4]),
  items: PropTypes.object,
};
BoxSection.defaultProps = {
  layout: 1,
};

export default BoxSection;
